import {createStore} from 'vuex'
import { ref, shallowRef } from 'vue'

import Wallet from '../classes/wallet.js';

import Backend from '../classes/backend.js';
import * as constants from "../constants";
import config from '../config.js';
import {memberships,boost} from "../constants";

import imgWax from "../assets/svg-icons/icon-wax.svg";
import imgSol from "../assets/svg-icons/icon-sol.svg";
import imgBnb from "../assets/svg-icons/icon-bnb.svg";
import imgPol from "../assets/svg-icons/icon_pol.svg";

const defaultChain = 'WAX';
const wallet = shallowRef(null);
wallet.value = new Wallet(defaultChain);
const renderTrigger = ref(0);


function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

// How often to ping getUser to check for new balance
const getUserTimeoutTime = 2000;
// How many times to try pining getUser until giving up
const getUserMaxTries = 30;
let getUserTries = 0;

let getBetsTimeout;
let getLeaderboardTimeout;
let updateMillisecondsRemainingInterval;

let initialFrontendTimestamp;
let initialMillisecondsRemainingToday;
let initialMillisecondsRemainingThisWeek;
let initialMillisecondsRemainingThisMonth;

const leaderboardUpdateTime = 60;
const recentBetsUpdateTime = 10;

function blackSheepCreditCard(userId, productId, quantity, callback, onDestroy) {
  console.log("buying", quantity, productId);
  BlackSheepMX.Pay(productId, {
    host: 'blockspingaming.net',
    theme: 'light',
    qty: quantity,
    customerId: userId,
    callback,
    onDestroy,
    noSuccess: true
  });
}

export const store = createStore({
  state: {
    chain: defaultChain,
    walletBalance: 0,
    wallet,
    bets: {
      slot: {
        all: [],
        big: [],
        mine: []
      },
      scratch: {
        all: [],
        big: [],
        mine: []
      }
    },
    leaderboard: {
      slot: {
        current:
            {
              hourly: {
                users: [],
                myPoints: 0,
              },
              daily: {
                users: [],
                myPoints: 0,
              },
              weekly: {
                users: [],
                myPoints: 0,
              },
              games: {
                users: [],
                myPoints: 0,
                myPrize:0,
                myProgress:{},
              },
            },
        previous: {
          hourly: {
            users: [],
            myPoints: 0,
          },
          daily: {
            users: [],
            myPoints: 0,
          },
          weekly: {
            users: [],
            myPoints: 0,
          },
          games: {
            users: [],
            myPoints: 0,
            myPrize:0,
          },
        }
      },
      scratch: {
        current:
            {
              hourly: {
                users: [],
                myPoints: 0,
              },
              daily: {
                users: [],
                myPoints: 0,
              },
              weekly: {
                users: [],
                myPoints: 0,
              },
            },
        previous: {
          hourly: {
            users: [],
            myPoints: 0,
          },
          daily: {
            users: [],
            myPoints: 0,
          },
          weekly: {
            users: [],
            myPoints: 0,
          },
        }
      }
    },

    loginData: null,
    userId: "",
    loginMethod: "",
    username: "",
    discordUsername:"",
    avatar: "",
    numInvited: 0,
    referralCode: "",

    chips: 0,
    gems: 0,
    experience: 0,
    recoveryExperience: 0,
    dailyStreak: 0,
    bronzeTickets: 0,
    silverTickets: 0,
    goldTickets: 0,
    achievements:[],
    unlockedGames:{},
    numEntries: { daily: 0, weekly: 0, monthly: 0},
    userPrizes: [],
    claimedFreeChipsToday: false,
    numScratchCards: {},
    userMonthlyCard: {},
    userBoosts: {},
    userTokenBalances: [],
    userTokenWithdrawals: [],
    numBets: 0,
    totalWon: 0,
    nextWheelSpinTimestamp: 0,
    lastWheelSpinPrize: {prizeType: null, prizeAmount: null, game: null},
    isInitialLoginData: false,

    chipsPrice: [0,0,0,0,0],
    chipsAmount: [0,0,0,0,0],
    chipsDiscount: [0,0,0,0,0],
    startChipsDiscord: 0,
    startChipsNonDiscord: 0,
    referralChipsBonus: 0,
    dailyChipsDiscord: 0,
    dailyChipsNonDiscord: 0,
    bronzeRaffleGemPrice: 0,
    silverRaffleGemPrice: 0,
    goldRaffleGemPrice: 0,
    waxUsdPrice: 0,
    polUsdPrice: 0,
    bnbUsdPrice: 0,
    solUsdPrice: 0,
    roninUsdPrice: 0,
    xpPerLevelIncreasePercentage: 0,
    dailyChipsPerLevelIncreasePercentage: 0,
    betsPossible: [],
    bigWinMultiplier: 0,
    leaderboardPrizeHourly: {slot: 0},
    leaderboardPrizeDaily: {slot: 0},
    leaderboardPrizeWeekly: {slot: 0},
    leaderboardPrizeSplit: [],
    leaderboardTokenPrizes: {},
    gamesUnlockedForAll: [],
    gamesGemUnlockPrice: {},
    gamesGemUnlockDiscount: {},
    levelUpChipsRewardMultiplier: 0,
    achievementRewards: {},
    usdToGemsRate: 0,
    guestMaxBets: 0,
    luckyWheelChipsWinAmounts: [],
    boosts: {},
    errorPopupObj:{errorPopup:false,titleErrorPopup:'',textErrorPopup:''},
    monthlyCards: {},
    jackpot: 0,
    totalNumEntries: { daily: 0, weekly: 0, monthly: 0 },
    prizes: { daily: [], weekly: [], monthly: [] },
    winners: { daily: [], weekly: [], monthly: [] },
    timestamp: 0,
    millisecondsRemainingToday: 0,
    millisecondsRemainingThisWeek: 0,
    millisecondsRemainingThisMonth: 0,

    sound: true,
    notifications: [],

    selectedGamesType:"slot",

    isBuyChipsPopupOpen:false,
    isGuideOpen:false,
    isGuestGuideOpen:false,
    isChipsGuideOpen:false,
    isRedeemPopupOpen:false,
    isAchievementsPopupOpen:false,
    isReferralPopupOpen:false,
    isLoginPopupOpen:false,
    isWalletPopupOpen:false,
    isSignUpOpen:false,

    redeemedCodes:[],

    jackbotCooldown:5,
    jackbotPremiumServerCooldown:  3,
    jackbotPremiumServerDiscountPercentage  :  0,
    jackbotPremiumServerMaxBet  :  10000,
    jackbotPremiumServerPrice  :  9.99,
    jackbotPremiumUserCooldown  :  3,
    jackbotPremiumUserDiscountPercentage  :  0,
    jackbotPremiumUserMaxBet  : 10000,
    jackbotPremiumUserPrice  :  9.99,
    jackbotPackageID  :  "jackbotPremiumUser",

    gamesLeaderboardBetsPerGame:10,
    gamesLeaderboardMinimunBet  :  100,
    gamesLeaderboardPrize  :  100000,
    isCrashGamePage: false,
    popupLuckyWheel:{visible:false,},    //Popup for LuckyWheel
    blockspinState:{},
    userData:null,

    gamesGemMiningRate:{},
    pushNotifToken: null,
    popupUnlockConfirm:{visible:false,gameObj:null},    //game obj from GameList.js

    boost:boost,
    buyBoostConfirmationPopup:false,
    buyChipsConfirmationPopup:false,
    buyVipConfirmationPopup:false,
    buyChipsType: 0,
    chipsBuyAmountDeal: [1, 1, 1, 1, 1],
    isMobile:false,
    isShopWalletSelected: false,
    memberships:memberships,
    selectedChain:"wax",
    selectedBoost:"",
    selectedVip:"",
    shopCategory: "boost",
    shopCurrency: "wax",
    shopPopup: false,
    vipguidePopup: false,
    vipguideType: "bronze",
    loginPopup:false,
    metaMaskChain:"",         //last connected metamask chain
    ss:0.66,
    realWidth:1920,
    realHeight:1080,
    xMult:0.5,

    rafflePrizesPopup: false,
    prizesCategory: "daily",
  },
  getters: {
    boost: state=>state.boost,
    buyBoostConfirmationPopup: state=>state.buyBoostConfirmationPopup,
    buyChipsConfirmationPopup: state=>state.buyChipsConfirmationPopup,
    buyVipConfirmationPopup: state=>state.buyVipConfirmationPopup,
    buyChipsType: state=>state.buyChipsType,
    chipsBuyAmountDeal: state=>state.chipsBuyAmountDeal,
    chipsPrice: state=>state.chipsPrice,
    isMobile:state=>state.isMobile,
    isShopWalletSelected: state=>state.isShopWalletSelected,
    memberships: state=>state.memberships,
    selectedBoost: state=>state.selectedBoost,
    selectedVip: state=>state.selectedVip,
    selectedChain: state=>state.selectedChain,
    shopCategory: state=>state.shopCategory,
    shopCurrency: state=>state.shopCurrency,
    shopPopup: state=>state.shopPopup,
    vipguidePopup: state=>state.vipguidePopup,
    vipguideType: state=>state.vipguideType,
    loginPopup: state=>state.loginPopup,
    ss: state=>state.ss,
    realWidth: state=>state.realWidth,
    realHeight: state=>state.realHeight,
    xMult: state=>state.xMult,
    rafflePrizesPopup: state=>state.rafflePrizesPopup,
    prizesCategory: state=>state.prizesCategory,
    hasMembershipDiscount:(getters)=>{
      if(!getters.getUserMonthlyCard) return false
      let hasMemDiscount = false;
      for (let i = 0; i < 5; i++) {
        hasMemDiscount = hasMemDiscount || (getters.getChipsDiscount[i]<=getters.getUserMonthlyCard.chipsDiscountPercentage)
      }
      return hasMemDiscount
    },
    totalChipsPriceChainWithDiscount:(state,getters)=>{
      return getters.getChipsPriceChainWithDiscount.map((price, i) =>
          +((price * state.chipsBuyAmountDeal[i]).toFixed(getters.getChainDecimals))
      );
    },
    walletConnected:(state,getters)=>{
      let conn=false;
      if (getters.getWalletUsername && getters.shopCurrency!=='usd'){
        conn=true;
        if ((state.selectedChain==='bnb' && (state.metaMaskChain==='POL' || !state.metaMaskChain))||(state.selectedChain==='pol'&& (state.metaMaskChain==='BNB' || !state.metaMaskChain))){
          conn=false;
        }
      }
      return conn;
    },

    errorPopupObj: state=>state.errorPopupObj,
    getEnv:()=> {
      let url = window.location.hostname;
      if (url.includes("test.") || url.includes("localhost")) {
        return "test";
      } else {
        return "prod";
      }
    },
    getIsCrashGamePage: state => {
      return state.isCrashGamePage;
    },
    getPushNotifToken: state => {
      return state.pushNotifToken;
    },
    getRecentBetsUpdateTime: state => {
      return recentBetsUpdateTime;
    },
    getWalletUsername: state => {
      renderTrigger.value++;
      return state.wallet.getUsername();
    },
    getConnectedWallet: state => {
      if(state.wallet.getUsername()) {
        return state.wallet.wallet;
      }
      return null;
    },
    getWalletBalance: state => {
      if (state.wallet.getUsername()) {
        return state.walletBalance.toFixed(4) + ' ' + state.chain.toUpperCase();
      }
      return '0';
    },

    getGemsBalance: state => {
      return state.gems;
    },
    getChipsBalance: state => {
      return state.chips;
    },
    getUnlockedGames: state => {
      return state.gamesUnlockedForAll.concat(Object.keys(state.unlockedGames));
    },
    getRecoveryExperience: state => {
      return state.recoveryExperience;
    },
    getRecoveryLevel: state => {
      let xpForCurrentLevel = 1000;
      let xpPerLevelIncrease = 1000;

      for(let i = 0; i<100; i++) {
        if(state.experience+state.recoveryExperience < xpForCurrentLevel) {
          return i;
        }
        xpPerLevelIncrease = Math.round(xpPerLevelIncrease * (1+state.xpPerLevelIncreasePercentage/100));
        xpForCurrentLevel += xpPerLevelIncrease;
      }
      return 100;
    },
    getExperience: (state, getters) => {
      let level = getters.getLevel;

      if(level == 0) {
        return state.experience;
      }

      let xpForCurrentLevel = 1000;
      let xpPerLevelIncrease = 1000;

      for(let i = 0; i<level-1; i++) {
        xpPerLevelIncrease = Math.round(xpPerLevelIncrease * (1+state.xpPerLevelIncreasePercentage/100));
        xpForCurrentLevel += xpPerLevelIncrease;
      }

      return state.experience - xpForCurrentLevel;
    },
    getExperienceNext: (state, getters) => {
      let level = getters.getLevel;

      if(level == 100) {
        return 0;
      }

      let xpPerLevelIncrease = 1000;

      for(let i = 0; i<level; i++) {
        xpPerLevelIncrease = Math.round(xpPerLevelIncrease * (1+state.xpPerLevelIncreasePercentage/100));
      }

      return xpPerLevelIncrease;
    },
    getLevel: state => {
      let xpForCurrentLevel = 1000;
      let xpPerLevelIncrease = 1000;

      for(let i = 0; i<100; i++) {
        if(state.experience < xpForCurrentLevel) {
          return i;
        }
        xpPerLevelIncrease = Math.round(xpPerLevelIncrease * (1+state.xpPerLevelIncreasePercentage/100));
        xpForCurrentLevel += xpPerLevelIncrease;
      }
      return 100;
    },
    getLevelUpRewardAmount: (state, getters) => {
      return (Math.floor((getters.getLevel-1) / 10) + 1) * state.levelUpChipsRewardMultiplier;
    },
    getTitle: (state, getters) => {
      const level = getters.getLevel;
      for(let titleLevel of Object.keys(constants.titles).sort().reverse()) {
        if(level >= titleLevel) {
          return constants.titles[titleLevel];
        }
      }
      return "";
    },
    getDailyChipsReward: ( state, getters ) => {
      let numChips = 0;
      if(state.discordUsername) {
        numChips = state.dailyChipsDiscord;
        const level = getters.getLevel;
        numChips *= Math.pow(1+state.dailyChipsPerLevelIncreasePercentage/100, level);
        numChips = Math.ceil(numChips/10) * 10;
      } else {
        numChips = state.dailyChipsNonDiscord;
      }
      let dailyChipsBonusPercentage = getters.getUserMonthlyCard? getters.getUserMonthlyCard.dailyChipsBonusPercentage : 0;
      numChips = Math.ceil(numChips * (100+dailyChipsBonusPercentage)/100);

      return numChips;
    },
    getTicketsBalance: state => {
      return (state.bronzeTickets + state.silverTickets + state.goldTickets).toString() + ' Tickets';
    },
    getBronzeTicketsBalance: state => {
      return state.bronzeTickets;
    },
    getSilverTicketsBalance: state => {
      return state.silverTickets;
    },
    getGoldTicketsBalance: state => {
      return state.goldTickets;
    },
    getWalletLogo: state => {
      switch (state.chain.toLowerCase()){
        case "wax":{
          return imgWax
        }
        case "sol":{
          return imgSol
        }
        case "bnb":{
          return imgBnb
        }
        case "pol":{
          return imgPol
        }
        default:{
          return null
        }
      }
    },
    getLoginLogo: state => {
      switch (state.loginData.loginMethod){
        case "discord":{
          return "icon_discord"
        }
        case "google":{
          return "icon_google"
        }
        case "facebook":{
          return "icon_facebook"
        }
        default: {
          return "icon_discord";
        }
      }
    },
    getChain: state => {
      return state.chain;
    },
    getChainDecimals: state => {
      let dec=2;
      try{
        dec=config[state.chain].decimals;
      }catch (e) {}
      return dec
    },
    getBets: state => {
      return state.bets[state.selectedGamesType];
    },
    getLeaderboard: state => {
      return state.leaderboard[state.selectedGamesType];
    },
    getAchievements: state => {
      return state.achievements;
      //for testing
      //return [{ name: "chad", timestamp: 1667587655000 }, { name: "master", timestamp: 1667587655000 }, { name: "ghost", timestamp: 1667587655000 }, { name: "trophy", timestamp: 1667587655000 }, { name: "master", timestamp: 1667587655000 }, { name: "master", timestamp: 1667587655000 }];
    },
    getDailyStreak: state => {
      return state.dailyStreak;
    },
    getWinners: state => {
      return state.winners;
    },

    getUserId: state => {
      return state.userId;
    },
    getLoginMethod: state => {
      return state.loginData.loginMethod;
    },
    getLoginData: state => {
      return state.loginData;
    },
    getUsername: state => {
      return state.username;
    },
    getDiscordUsername: state => {
      return state.discordUsername;
    },
    getAvatar: state => {
      return state.avatar;
    },
    getNumInvited: state => {
      return state.numInvited;
    },
    getReferralCode: state => {
      return state.referralCode;
    },
    getNumEntries: state => {
      return state.numEntries;
    },
    getUserPrizes: state => {
      return state.userPrizes;
    },
    getIsInitialLoginData: state => {
      return state.isInitialLoginData;
    },

    getChipsPrice: state => {
      return state.chipsPrice.map(price =>
          +price.toFixed(2)
      );
    },
    getChipsPriceWithDiscount: (state, getters) => {
      let memberDiscount = getters.getUserMonthlyCard? getters.getUserMonthlyCard.chipsDiscountPercentage : 0;
      return state.chipsPrice.map((price, i) =>
          +(price * (100-Math.max(memberDiscount, state.chipsDiscount[i]))/100).toFixed(2)
      );
    },
    getConversion: (state) => {
      let chainPrice = 1;
      switch(state.chain) {
        case "WAX": {
          chainPrice = state.waxUsdPrice;
          break;
        }
        case "SOL": {
          chainPrice = state.solUsdPrice;
          break;
        }
        case "BNB": {
          chainPrice = state.bnbUsdPrice;
          break;
        }
        case "POL": {
          chainPrice = state.polUsdPrice;
          break;
        }
        case "RON": {
          chainPrice = state.roninUsdPrice;
          break;
        }
      }
      return chainPrice;
    },
    getChipsPriceChain: (state, getters) => {
      const chainPrice = getters.getConversion;

      return state.chipsPrice.map(price =>
          +(price/chainPrice).toFixed(getters.getChainDecimals)
      );
    },
    getChipsPriceChainWithDiscount: (state, getters) => {
      let memberDiscount = getters.getUserMonthlyCard? getters.getUserMonthlyCard.chipsDiscountPercentage : 0;
      const chainPrice = getters.getConversion;

      return state.chipsPrice.map((price, i) =>
          +(price/chainPrice * (100-Math.max(memberDiscount, state.chipsDiscount[i]))/100).toFixed(getters.getChainDecimals)
      );
    },
    getChipsAmount: state => {
      return state.chipsAmount;
    },
    getChipsDiscount: state => {
      return state.chipsDiscount;
    },

    getUsdToGemsRate: state => {
      return state.usdToGemsRate;
    },
    getGuestMaxBets: state => {
      return state.guestMaxBets;
    },
    getLuckyWheelChipsWinAmounts: state => {
      return state.luckyWheelChipsWinAmounts;
    },
    getBoostPrice: state => {
      let prices = {};
      for(let boost of Object.keys(state.boosts))
      {
        prices[boost] = +state.boosts[boost].price.toFixed(2);
      }
      return prices;
    },
    getBoostDiscount: state => {
      let discounts = {};
      for(let boost of Object.keys(state.boosts))
      {
        discounts[boost] = state.boosts[boost].discount;
      }
      return discounts;
    },
    getBoostPriceWithDiscount: state => {
      let prices = {};
      for(let boost of Object.keys(state.boosts))
      {
        prices[boost] = +(state.boosts[boost].price * (100 - state.boosts[boost].discount)/100).toFixed(2);
      }
      return prices;
    },
    getBoostPriceChain: (state, getters) => {
      const chainPrice = getters.getConversion;

      let prices = {};
      for(let boost of Object.keys(state.boosts))
      {
        prices[boost] = +(state.boosts[boost].price/chainPrice).toFixed(getters.getChainDecimals);
      }
      return prices;
    },
    getBoostPriceChainWithDiscount: (state, getters) => {
      const chainPrice = getters.getConversion;

      let prices = {};
      for(let boost of Object.keys(state.boosts))
      {
        prices[boost] = +(state.boosts[boost].price/chainPrice * (100 - state.boosts[boost].discount)/100).toFixed(getters.getChainDecimals);
      }
      return prices;
    },
    getBoostPriceGems: (state, getters) => {
      let prices = {};
      for(let boost of Object.keys(state.boosts))
      {
        prices[boost] = Math.round(state.boosts[boost].price*state.usdToGemsRate);
      }
      return prices;
    },
    getBoostPriceGemsWithDiscount: (state, getters) => {
      let prices = {};
      for(let boost of Object.keys(state.boosts))
      {
        prices[boost] = Math.round(state.boosts[boost].price*state.usdToGemsRate * (100 - state.boosts[boost].discount)/100);
      }
      return prices;
    },

    getMonthlyCardPrice: state => {
      return {
        bronze: +state.monthlyCards.bronze.price.toFixed(2),
        silver: +state.monthlyCards.silver.price.toFixed(2),
        gold: +state.monthlyCards.gold.price.toFixed(2),
      };
    },
    getMonthlyCardDiscount: state => {
      return {
        bronze: +state.monthlyCards.bronze.discount,
        silver: +state.monthlyCards.silver.discount,
        gold: +state.monthlyCards.gold.discount,
      };
    },
    getMonthlyCardPriceWithDiscount: state => {
      return {
        bronze: +(state.monthlyCards.bronze.price * (100 - state.monthlyCards.bronze.discount)/100).toFixed(2),
        silver: +(state.monthlyCards.silver.price * (100 - state.monthlyCards.silver.discount)/100).toFixed(2),
        gold: +(state.monthlyCards.gold.price * (100 - state.monthlyCards.gold.discount)/100).toFixed(2),
      };
    },
    getMonthlyCardPriceChain: (state, getters) => {
      const chainPrice = getters.getConversion;

      return {
        bronze: +(state.monthlyCards.bronze.price/chainPrice).toFixed(getters.getChainDecimals),
        silver: +(state.monthlyCards.silver.price/chainPrice).toFixed(getters.getChainDecimals),
        gold: +(state.monthlyCards.gold.price/chainPrice).toFixed(getters.getChainDecimals),
      };
    },
    getMonthlyCardPriceChainWithDiscount: (state, getters) => {
      const chainPrice = getters.getConversion;

      return {
        bronze: +(state.monthlyCards.bronze.price/chainPrice * (100 - state.monthlyCards.bronze.discount)/100).toFixed(getters.getChainDecimals),
        silver: +(state.monthlyCards.silver.price/chainPrice * (100 - state.monthlyCards.silver.discount)/100).toFixed(getters.getChainDecimals),
        gold: +(state.monthlyCards.gold.price/chainPrice * (100 - state.monthlyCards.gold.discount)/100).toFixed(getters.getChainDecimals),
      };
    },

    getStartChipsDiscord: state => {
      return state.startChipsDiscord;
    },
    getStartChipsNonDiscord: state => {
      return state.startChipsNonDiscord;
    },
    getReferralChipsBonus: state => {
      return state.referralChipsBonus;
    },
    getBronzeRaffleGemPrice: state => {
      return state.bronzeRaffleGemPrice;
    },
    getSilverRaffleGemPrice: state => {
      return state.silverRaffleGemPrice;
    },
    getGoldRaffleGemPrice: state => {
      return state.goldRaffleGemPrice;
    },
    getLeaderboardPrizeHourly: state => {
      return state.leaderboardPrizeHourly[state.selectedGamesType];
    },
    getLeaderboardPrizeDaily: state => {
      return state.leaderboardPrizeDaily[state.selectedGamesType];
    },
    getLeaderboardPrizeWeekly: state => {
      return state.leaderboardPrizeWeekly[state.selectedGamesType];
    },
    getLeaderboardPrizeSplit: state => {
      return state.leaderboardPrizeSplit;
    },
    getLeaderboardTokenPrizes: state => {
      return state.leaderboardTokenPrizes;
    },
    getGamesGemUnlockPrice: state => {
      let gamesGemUnlockPrice = {...state.gamesGemUnlockPrice};
      for(let game of Object.keys(constants.allGames)) {
        if(!(gamesGemUnlockPrice.hasOwnProperty(game))) {
          gamesGemUnlockPrice[game] = 0;
        }
      }
      return gamesGemUnlockPrice;
    },
    getGamesGemUnlockDiscount: state => {
      let gamesGemUnlockDiscount = {...state.gamesGemUnlockDiscount};
      for(let game of Object.keys(constants.allGames)) {
        if(!(gamesGemUnlockDiscount.hasOwnProperty(game))) {
          gamesGemUnlockDiscount[game] = 0;
        }
      }
      return gamesGemUnlockDiscount;
    },
    getAchievementRewards: state => {
      return state.achievementRewards;
    },
    getMonthlyCards: state => {
      return state.monthlyCards;
    },
    getJackpot: state => {
      return state.jackpot;
    },
    getJackbotCooldown: state =>{
      return state.jackbotCooldown;
    },
    getJackbotPremiumServerCooldown: state =>{
      return state.jackbotPremiumServerCooldown;
    },
    getJackbotPremiumServerDiscountPercentage: state =>{
      return state.jackbotPremiumServerDiscountPercentage;
    },
    getJackbotPremiumServerMaxBet: state =>{
      return state.jackbotPremiumServerMaxBet;
    },
    getJackbotPremiumServerPrice: state =>{
      return state.jackbotPremiumServerPrice;
    },
    getJackbotPremiumUserCoold: state =>{
      return state.jackbotPremiumUserCoold;
    },
    getJackbotPremiumUserDiscountPercentage: state =>{
      return state.jackbotPremiumUserDiscountPercentage;
    },
    getJackbotPremiumUserMaxBet: state =>{
      return state.jackbotPremiumUserMaxBet;
    },
    getJackbotPremiumUserPrice: state =>{
      return state.jackbotPremiumUserPrice;
    },
    getJackbotPackageID: state =>{
      return state.jackbotPackageID;
    },
    getBetsPossible: (state, getters) => {
      let maxBet = 1000;
      if(getters.getUserMonthlyCard) {
        maxBet = getters.getUserMonthlyCard.maxBet;
      }
      let betsPossible = state.betsPossible.filter(bet => bet <= maxBet);
      return betsPossible;
    },
    getTotalNumEntries: state => {
      return state.totalNumEntries;
    },
    getPrizes: state => {
      return state.prizes;
    },
    getTimestamp: state => {
      return state.timestamp;
    },
    getMillisecondsRemainingToday: state => {
      return state.millisecondsRemainingToday;
    },
    getMillisecondsRemainingThisWeek: state => {
      return state.millisecondsRemainingThisWeek;
    },
    getMillisecondsRemainingThisMonth: state => {
      return state.millisecondsRemainingThisMonth;
    },
    getClaimedFreeChipsToday: state => {
      return state.claimedFreeChipsToday;
    },
    getNumScratchCards: state => {
      return state.numScratchCards;
    },
    getUserMonthlyCard: state => {
      if(!state.userMonthlyCard.expiry || state.userMonthlyCard.expiry < state.timestamp) {
        return null;
      }
      return state.userMonthlyCard;
    },
    getUserBoosts: state => {
      let userBoosts = state.userBoosts;
      if(userBoosts.xpBoost && userBoosts.xpBoost < state.timestamp) {
        delete userBoosts.xpBoost;
      } else if(userBoosts.megaXpBoost && userBoosts.megaXpBoost < state.timestamp) {
        delete userBoosts.megaXpBoost;
      } else if(userBoosts.daysStreakInsuranceRemaining == 0) {
        delete userBoosts.daysStreakInsuranceRemaining;
      }
      return userBoosts;
    },
    getUserTokenBalances: state => {
      return state.userTokenBalances;
    },
    getUserTokenWithdrawals: state => {
      return state.userTokenWithdrawals;
    },
    getNumBets: state => {
      return state.numBets;
    },
    getTotalWon: state => {
      return state.totalWon;
    },
    getNextWheelSpinTimestamp: state => {
      return state.nextWheelSpinTimestamp;
    },
    getLastWheelSpinPrize: state => {
      return state.lastWheelSpinPrize;
    },
    getIsGuest: state => {
      return !!(state.loginData && state.loginData.loginMethod == "guest");

    },
    getIsGuestAndOutOfBets: state => {
      if(state.loginData && state.loginData.loginMethod == "guest") {
        if(state.numBets >= state.guestMaxBets) {
          return true;
        }
      }
      return false;
    },

    getSound: state => {
      return state.sound;
    },

    getSelectedGamesType: state => {
      return state.selectedGamesType;
    },

    getNotifications: state => {
      return state.notifications;
    },

    getIsBuyChipsPopupOpen: state => {
      return state.isBuyChipsPopupOpen;
    },

    getIsGuideOpen: state => {
      return state.isGuideOpen;
    },

    getRedeemedCodes: state => {
      return state.redeemedCodes;
    },

    getIsGuestGuideOpen: state => {
      return state.isGuestGuideOpen;
    },

    getIsChipsGuideOpen: state => {
      return state.isChipsGuideOpen;
    },

    getIsRedeemPopupOpen: state => {
      return state.isRedeemPopupOpen;
    },

    getIsAchievementsPopupOpen: state => {
      return state.isAchievementsPopupOpen;
    },

    getIsReferralPopupOpen: state => {
      return state.isReferralPopupOpen;
    },

    getIsLoginPopupOpen: state => {
      return state.isLoginPopupOpen;
    },
    getIsWalletPopupOpen: state => {
      return state.isWalletPopupOpen;
    },
    getIsSignUpOpen: state => {
      return state.isSignUpOpen;
    },
    getGamesLeaderboardBetsPerGame: state =>{
      return state.gamesLeaderboardBetsPerGame;
    },
    getGamesLeaderboardMinimunBet: state =>{
      return state.gamesLeaderboardMinimunBet;
    },
    getGamesLeaderboardPrize: state =>{
      return state.gamesLeaderboardPrize;
    },
    getIsAchievementsStatsUnlocked: state =>{
      return !!state.numBets;
    },
    popupLuckyWheel: state => state.popupLuckyWheel,
    blockspinState: state => state.blockspinState,
    userData: state => state.userData,
    getGamesGemMiningRate: state => state.gamesGemMiningRate,
    popupUnlockConfirm: state => state.popupUnlockConfirm,
  },
  mutations: {
    setIsCrashGamePage(state, isCrashGamePage) {
      state.isCrashGamePage = isCrashGamePage;
    },
    setPushNotifToken(state, pushNotifToken) {
       state.pushNotifToken = pushNotifToken;
    },
    setChain(state, chain) {
      state.selectedChain=chain.toLowerCase();//!=='usd'?chain.toLowerCase():state.selectedChain;
      state.chain = chain;
      if (state.selectedChain!=='usd') {
        /*if ((state.selectedChain==='bnb'&& state.wallet.chain==='POL')||(state.selectedChain==='pol'&& state.wallet.chain==='BNB')){}
        else {
          wallet.value.setChain(chain);
          state.wallet.setChain(chain);
          renderTrigger.value++;
        }//*/
        wallet.value.setChain(chain);
        state.wallet.setChain(chain);
        renderTrigger.value++;
      }
    },
    setChainHard(state, chain) {
      state.selectedChain=chain.toLowerCase();//!=='usd'?chain.toLowerCase():state.selectedChain;
      state.chain = chain;
      if (state.selectedChain!=='usd') {
        wallet.value.setChain(chain)
        state.wallet.setChain(chain);
        renderTrigger.value++;
      }
    },

    setWalletBalance(state, amount) {
      state.walletBalance = amount;
    },
    setBets(state, bets) {
      state.bets = bets;
    },
    setNewBet(state, newBet) {
      state.bets.slot.mine.unshift(newBet);
      if(state.bets.slot.mine.length > 20) {
        state.bets.slot.mine.pop();
      }
      state.bets.slot.all.unshift(newBet);
      if(state.bets.slot.all.length > 20) {
        state.bets.slot.all.pop();
      }
      state.bets.slot.all.pop();
      if(newBet.chipsWon > state.bets.slot.big[state.bets.slot.big.length-1]) {
        state.bets.slot.big.unshift(newBet);
        if(state.bets.slot.big.length > 20) {
          state.bets.slot.big.pop();
        }
        state.bets.slot.big.sort((a,b) => b.chipsWon - a.chipsWon);
      }
    },
    setLeaderboard(state, leaderboard) {
      state.leaderboard = leaderboard;
    },
    setAchievements(state, achievements){
      state.achievements = achievements;
    },
    setUnlockedGames(state, unlockedGames){
      state.unlockedGames = unlockedGames;
    },
    setUnlockedGame(state, unlockedGame){
      state.unlockedGames[unlockedGame]= 0;
    },
    setIsInitialLoginData(state, isInitialLoginData){
      state.isInitialLoginData = isInitialLoginData;
    },

    setLoginData(state, loginData){
      state.loginData = loginData;
    },
    setUserId(state, userId){
      state.userId = userId;
    },
    setUsername(state, username){
      state.username = username;
    },
    setDiscordUsername(state, discordUsername){
      state.discordUsername = discordUsername;
    },
    setAvatar(state, avatar){
      state.avatar = avatar;
    },
    setNumInvited(state, numInvited) {
      state.numInvited = numInvited;
    },
    setReferralCode(state, referralCode) {
      state.referralCode = referralCode;
    },

    setChips(state, chips){
      state.chips = chips;
    },
    setGems(state, gems){
      state.gems = gems;
    },
    setExperience(state, experience){
      if(isFinite(experience)) {
        state.experience = experience;
      }
    },
    setRecoveryExperience(state, recoveryExperience){
      state.recoveryExperience = recoveryExperience;
    },
    setDailyStreak(state, dailyStreak){
      state.dailyStreak = dailyStreak;
    },
    setBronzeTickets(state, bronzeTickets){
      state.bronzeTickets = bronzeTickets;
    },
    setSilverTickets(state, silverTickets){
      state.silverTickets = silverTickets;
    },
    setGoldTickets(state, goldTickets){
      state.goldTickets = goldTickets;
    },
    setNumEntries(state, numEntries){
      state.numEntries = numEntries;
    },
    setUserPrizes(state, userPrizes){
      state.userPrizes = userPrizes;
    },
    setClaimedFreeChipsToday(state, claimedFreeChipsToday) {
      state.claimedFreeChipsToday = claimedFreeChipsToday;
    },
    setNumScratchCards(state, numScratchCards) {
      state.numScratchCards = numScratchCards;
    },
    setUserMonthlyCard(state, userMonthlyCard) {
      state.userMonthlyCard = userMonthlyCard;
    },
    setUserBoosts(state, userBoosts) {
      state.userBoosts = userBoosts;
    },
    setUserTokenBalances(state, userTokenBalances) {
      state.userTokenBalances = userTokenBalances;
    },
    setUserTokenWithdrawals(state, userTokenWithdrawals) {
      state.userTokenWithdrawals = userTokenWithdrawals;
    },
    setNumBets(state, numBets) {
      state.numBets = numBets;
    },
    setTotalWon(state, totalWon) {
      state.totalWon = totalWon;
    },
    setNextWheelSpinTimestamp(state, nextWheelSpinTimestamp) {
      state.nextWheelSpinTimestamp = nextWheelSpinTimestamp;
    },
    setLastWheelSpinPrize(state, lastWheelSpinPrize) {
      state.lastWheelSpinPrize = lastWheelSpinPrize;
    },
    setNotifications(state, notifications) {
      state.notifications = notifications;
    },

    setPostSpinUserData(state, user) {
      state.gems = user.gems;
      state.chips = user.chips;
      state.dailyStreak = user.dailyStreak;
      state.experience = user.experience;
      state.achievements = user.achievements;
      state.isInitialLoginData = false;
    },

    setChipsPrice(state, chipsPrice){
      state.chipsPrice = chipsPrice;
    },
    setChipsAmount(state, chipsAmount){
      state.chipsAmount = chipsAmount;
    },
    setChipsDiscount(state, chipsDiscount){
      state.chipsDiscount = chipsDiscount;
    },
    setDailyChipsDiscord(state, dailyChipsDiscord){
      state.dailyChipsDiscord = dailyChipsDiscord;
    },
    setStartChipsDiscord(state, startChipsDiscord){
      state.startChipsDiscord = startChipsDiscord;
    },
    setStartChipsNonDiscord(state, startChipsNonDiscord){
      state.startChipsNonDiscord = startChipsNonDiscord;
    },
    setReferralChipsBonus(state, referralChipsBonus){
      state.referralChipsBonus = referralChipsBonus;
    },
    setDailyChipsNonDiscord(state, dailyChipsNonDiscord){
      state.dailyChipsNonDiscord = dailyChipsNonDiscord;
    },
    setBronzeRaffleGemPrice(state, bronzeRaffleGemPrice){
      state.bronzeRaffleGemPrice = bronzeRaffleGemPrice;
    },
    setSilverRaffleGemPrice(state, silverRaffleGemPrice){
      state.silverRaffleGemPrice = silverRaffleGemPrice;
    },
    setGoldRaffleGemPrice(state, goldRaffleGemPrice){
      state.goldRaffleGemPrice = goldRaffleGemPrice;
    },
    setWaxUsdPrice(state, waxUsdPrice){
      state.waxUsdPrice = waxUsdPrice;
    },
    setpolUsdPrice(state, polUsdPrice){
      state.polUsdPrice = polUsdPrice;
    },
    setBnbUsdPrice(state, bnbUsdPrice){
      state.bnbUsdPrice = bnbUsdPrice;
    },
    setSolUsdPrice(state, solUsdPrice){
      state.solUsdPrice = solUsdPrice;
    },
    setRoninUsdPrice(state, roninUsdPrice){
      state.roninUsdPrice = roninUsdPrice;
    },
    setJackpot(state, jackpot){
      state.jackpot = jackpot;
    },
    setXpPerLevelIncreasePercentage(state, xpPerLevelIncreasePercentage){
      state.xpPerLevelIncreasePercentage = xpPerLevelIncreasePercentage;
    },
    setDailyChipsPerLevelIncreasePercentage(state, dailyChipsPerLevelIncreasePercentage){
      state.dailyChipsPerLevelIncreasePercentage = dailyChipsPerLevelIncreasePercentage;
    },
    setBetsPossible(state, betsPossible){
      state.betsPossible = betsPossible;
    },
    setBigWinMultiplier(state, bigWinMultiplier){
      state.bigWinMultiplier = bigWinMultiplier;
    },
    setLeaderboardPrizeHourly(state, leaderboardPrizeHourly){
      state.leaderboardPrizeHourly = leaderboardPrizeHourly;
    },
    setLeaderboardPrizeDaily(state, leaderboardPrizeDaily){
      state.leaderboardPrizeDaily = leaderboardPrizeDaily;
    },
    setLeaderboardPrizeWeekly(state, leaderboardPrizeWeekly){
      state.leaderboardPrizeWeekly = leaderboardPrizeWeekly;
    },
    setLeaderboardPrizeSplit(state, leaderboardPrizeSplit){
      state.leaderboardPrizeSplit = leaderboardPrizeSplit;
    },
    setLeaderboardTokenPrizes(state, leaderboardTokenPrizes){
      state.leaderboardTokenPrizes = leaderboardTokenPrizes;
    },
    setGamesUnlockedForAll(state, gamesUnlockedForAll){
      state.gamesUnlockedForAll = gamesUnlockedForAll;
    },
    setGamesGemUnlockPrice(state, gamesGemUnlockPrice){
      state.gamesGemUnlockPrice = gamesGemUnlockPrice;
    },
    setGamesGemUnlockDiscount(state, gamesGemUnlockDiscount){
      state.gamesGemUnlockDiscount = gamesGemUnlockDiscount;
    },
    setLevelUpChipsRewardMultiplier(state, levelUpChipsRewardMultiplier) {
      state.levelUpChipsRewardMultiplier = levelUpChipsRewardMultiplier;
    },
    setAchievementRewards(state, achievementRewards){
      state.achievementRewards = achievementRewards;
    },
    setUsdToGemsRate(state, usdToGemsRate){
      state.usdToGemsRate = usdToGemsRate;
    },
    setGuestMaxBets(state, guestMaxBets){
      state.guestMaxBets = guestMaxBets;
    },
    setLuckyWheelChipsWinAmounts(state, luckyWheelChipsWinAmounts){
      state.luckyWheelChipsWinAmounts = luckyWheelChipsWinAmounts;
    },
    setBoosts(state, boosts){
      state.boosts = boosts;
    },
    setMonthlyCards(state, monthlyCards){
      state.monthlyCards = monthlyCards;
    },
    setTotalNumEntries(state, totalNumEntries){
      state.totalNumEntries = totalNumEntries;
    },
    setPrizes(state, prizes){
      state.prizes = prizes;
    },
    setWinners(state, winners){
      state.winners = winners;
    },
    setTimestamp(state, timestamp) {
      state.timestamp = timestamp;
    },
    setMillisecondsRemainingToday(state, millisecondsRemainingToday) {
      state.millisecondsRemainingToday = millisecondsRemainingToday;
    },
    setMillisecondsRemainingThisWeek(state, millisecondsRemainingThisWeek) {
      state.millisecondsRemainingThisWeek = millisecondsRemainingThisWeek;
    },
    setMillisecondsRemainingThisMonth(state, millisecondsRemainingThisMonth) {
      state.millisecondsRemainingThisMonth = millisecondsRemainingThisMonth;
    },
    setGamesLeaderboardBetsPerGame(state, gamesLeaderboardBetsPerGame) {
      state.gamesLeaderboardBetsPerGame = gamesLeaderboardBetsPerGame;
    },
    setGamesLeaderboardMinimunBet(state, gamesLeaderboardMinimunBet) {
      state.gamesLeaderboardPrize = gamesLeaderboardMinimunBet;
    },
    setGamesLeaderboardPrize(state, gamesLeaderboardPrize) {
      state.gamesLeaderboardPrize = gamesLeaderboardPrize;
    },

    setGamesGemMiningRate(state, gamesGemMiningRate) {
      state.gamesGemMiningRate = gamesGemMiningRate;
    },

    setSound(state, sound) {
      if (sound === "on" || sound === "off")
        state.sound = sound === "on";
    },
    setNotificationOpened(state, index){
      state.notifications[index].isRead=true;
    },
    setNotificationClaimed(state, index){
      state.notifications[index].isClaimed=true;
    },
    setSelectedGamesType(state, type){
      state.selectedGamesType=type;
    },
    setBuyChipsPopupOpen(state, bool){
      state.isBuyChipsPopupOpen=bool;
    },
    setShopPopupOpen(state, bool){
      state.shopPopup=bool;
    },
    setJackbotCooldown(state, jackbotCooldown){
      state.jackbotCooldown = jackbotCooldown;
    },
    setJackbotPremiumServerCooldown(state, jackbotPremiumServerCooldown){
      state.jackbotPremiumServerCooldown = jackbotPremiumServerCooldown;
    },
    setJackbotPremiumServerDiscountPercentage(state, jackbotPremiumServerDiscountPercentage){
      state.jackbotPremiumServerDiscountPercentage = jackbotPremiumServerDiscountPercentage;
    },
    setJackbotPremiumServerMaxBet(state, jackbotPremiumServerMaxBet){
      state.jackbotPremiumServerMaxBet = jackbotPremiumServerMaxBet;
    },
    setJackbotPremiumServerPrice(state, jackbotPremiumServerPrice){
      state.jackbotPremiumServerPrice = jackbotPremiumServerPrice;
    },
    setJackbotPremiumUserCoold(state, jackbotPremiumUserCoold){
      state.jackbotPremiumUserCoold = jackbotPremiumUserCoold;
    },
    setJackbotPremiumUserDiscountPercentage(state, jackbotPremiumUserDiscountPercentage){
      state.jackbotPremiumUserDiscountPercentage = jackbotPremiumUserDiscountPercentage;
    },
    setJackbotPremiumUserMaxBet(state, jackbotPremiumUserMaxBet){
      state.jackbotPremiumUserMaxBet = jackbotPremiumUserMaxBet;
    },
    setJackbotPremiumUserPrice(state, jackbotPremiumUserPrice){
      state.jackbotPremiumUserPrice = jackbotPremiumUserPrice;
    },
    setJackbotPackageID(state, jackbotPackageID){
      state.jackbotPackageID = jackbotPackageID;
    },
    setGuideOpen(state, bool){
      if(bool) {
        state.isGuideOpen = bool;
      }else{
        state.isChipsGuideOpen = bool;
        state.isGuideOpen = bool;
      }
    },
    setGuestGuideOpen(state, bool){
      state.isGuestGuideOpen = bool;
    },
    setRedeemedCodes(state, code){
      state.redeemedCodes.push(code);
    },
    setChipsGuideOpen(state, bool){
      if(bool) {
        state.isChipsGuideOpen = bool;
      }else{
        state.isChipsGuideOpen = bool;
        state.isGuideOpen = bool;
      }
    },
    setRedeemPopupOpen(state, bool){
      state.isRedeemPopupOpen=bool;
    },
    setAchievementsPopupOpen(state, bool){
      state.isAchievementsPopupOpen=bool;
    },
    setReferralPopupOpen(state, bool){
      state.isReferralPopupOpen=bool;
    },
    setLoginPopupOpen(state, bool){
      state.isWalletPopupOpen=false;
      state.isLoginPopupOpen=bool;
    },
    setWalletPopupOpen(state, bool){
      state.isLoginPopupOpen=bool;
      state.isWalletPopupOpen=bool;
    },
    setSignUpOpen(state, bool){
      state.isSignUpOpen=bool;
    },
  },
  actions: {
    async login({ commit, dispatch }, login) {
      const loginResponse = await Backend.login(
          login.loginMethod,
          login.accessToken,
          login.tokenType,
          login.code,
          login.redirectUri,
          login.referralCode,
          login.jumpTask,
          login.loginData
      );

      if(!loginResponse.error) {
        localStorage.setItem("loginData", JSON.stringify(loginResponse.loginData))

        commit('setLoginData', loginResponse.loginData);
        await dispatch('setUserData', loginResponse.user);
        return loginResponse;
      } else {
        return loginResponse;
      }
    },
    async connectDiscord({ commit, dispatch }, connectData) {
      const loginData = JSON.parse(localStorage.getItem("loginData"));
      const connectDiscordResponse = await Backend.connectDiscord(loginData, connectData.accessToken, connectData.tokenType);

      if(!connectDiscordResponse.error) {
        localStorage.setItem("loginData", JSON.stringify(connectDiscordResponse.loginData))

        commit('setLoginData', connectDiscordResponse.loginData);
        await dispatch('setUserData', connectDiscordResponse.user);
      }
    },
    async getUser({ commit, dispatch, getters, state }, untilUpdated = null) {
      if(localStorage.getItem("loginData")) {
        const loginData = JSON.parse(localStorage.getItem("loginData"));
        const user = await Backend.getUser(loginData);
        //console.log(user);

        if(!user.error) {
          if(getters.getUserId && untilUpdated) {
            if((untilUpdated == 'chips' && user.chips == getters.getChipsBalance) ||
                (untilUpdated == 'monthlyCard' && (!user.monthlyCard.expiry || (getters.getUserMonthlyCard && user.monthlyCard.expiry == getters.getUserMonthlyCard.expiry))) ||
                (untilUpdated == 'boosts' && user.boosts.daysStreakInsuranceRemaining == getters.getUserBoosts.daysStreakInsuranceRemaining && user.boosts.xpFreeze == getters.getUserBoosts.xpFreeze && user.boosts.xpBoost == getters.getUserBoosts.xpBoost && user.boosts.megaXpBoost == getters.getUserBoosts.megaXpBoost && user.recoveryExperience == getters.getRecoveryExperience)
            ) {
              getUserTries++;
              if(getUserTries < getUserMaxTries) {
                setTimeout(function() {
                  dispatch("getUser", untilUpdated);
                }, getUserTimeoutTime)
              }
            }
          }
          commit('setLoginData', loginData);
          state.userData=user;
          await dispatch('setUserData', user);
        } else if(user.error == 'User not found' || user.error == "Auth Token Expired" || user.error == 'Authentication Error' || user.error == '') {
          localStorage.removeItem("loginData");
        }
      }
    },
    async setUserData({ commit, getters }, user) {
      commit('setIsInitialLoginData', true);
      commit('setUserId', getters.getLoginData.userId);
      commit('setUsername', user.username);
      commit('setAvatar', user.avatar);
      commit('setNumInvited', user.numReferred);
      commit('setReferralCode', user.referralCode);
      commit('setChips', user.chips);
      commit('setGems', user.gems);
      commit('setRecoveryExperience', user.recoveryExperience);
      commit('setExperience', user.experience);
      commit('setDailyStreak', user.dailyStreak);
      commit('setBronzeTickets', user.bronzeTickets);
      commit('setSilverTickets', user.silverTickets);
      commit('setGoldTickets', user.goldTickets);
      commit('setNumEntries', user.numEntries);
      commit('setUserPrizes', user.prizes);
      commit('setAchievements', user.achievements);
      commit('setUnlockedGames', user.unlockedGames);
      commit('setClaimedFreeChipsToday', user.claimedFreeChipsToday);
      commit('setNumScratchCards', user.numScratchCards);
      commit('setUserMonthlyCard', user.monthlyCard);
      commit('setUserBoosts', user.boosts);
      commit('setUserTokenBalances', user.tokenBalances);
      commit('setUserTokenWithdrawals', user.tokenWithdrawals);
      commit('setNumBets', user.numBets);
      commit('setTotalWon', user.totalWon);
      commit('setNextWheelSpinTimestamp', user.nextWheelSpinTimestamp);
      commit('setLastWheelSpinPrize', user.lastWheelSpinPrize);
      commit('setNotifications', user.notifications);

      if(getters.getLoginData.loginMethod == "discord") {
        commit('setDiscordUsername', user.username);
      } else if(user.discordUsername) {
        commit('setDiscordUsername', user.discordUsername);
      }
    },
    async logout({ commit, getters }) {
      Backend.logout(getters.getLoginData);

      commit('setUserId', "");
      commit('setUsername', "");
      commit('setDiscordUsername', "");
      commit('setAvatar', "");
      commit('setNumInvited', 0);
      commit('setReferralCode', "");

      commit('setChips', 0);
      commit('setGems', 0);
      commit('setExperience', 0);
      commit('setRecoveryExperience', 0);
      commit('setBronzeTickets', 0);
      commit('setSilverTickets', 0);
      commit('setGoldTickets', 0);
      commit('setNumEntries', { daily: 0, weekly: 0, monthly: 0});
      commit('setUserPrizes', [] );
      commit('setAchievements', []);
      commit('setUnlockedGames', {});
      commit('setClaimedFreeChipsToday', false);
      commit('setNumScratchCards', {});
      commit('setNotifications', []);
      commit('setUserMonthlyCard', {});
      commit('setUserBoosts', {});
      commit('setUserTokenBalances', []);
      commit('setUserTokenWithdrawals', []);
      commit('setNumBets', 0);
      commit('setTotalWon', 0);
      commit('setNextWheelSpinTimestamp', 0);
      commit('setLastWheelSpinPrize', {prizeType: null, prizeAmount: null, game: null});

      if(getters.getLoginData.loginMethod != "guest") {
        localStorage.removeItem("loginData");
      }
      commit('setLoginData', null);
    },
    async getState({ commit, getters, dispatch, state }) {
      const blockspinState = await Backend.getState();
      state.blockspinState=blockspinState;
      //console.log(blockspinState);

      commit('setChipsPrice', blockspinState.chipsPrice);
      commit('setChipsAmount', blockspinState.chipsAmount);
      commit('setChipsDiscount', blockspinState.chipsDiscount);
      commit('setDailyChipsDiscord', blockspinState.dailyChipsDiscord);
      commit('setStartChipsDiscord', blockspinState.startChipsDiscord);
      commit('setStartChipsNonDiscord', blockspinState.startChipsNonDiscord);
      commit('setReferralChipsBonus', blockspinState.referralChipsBonus);
      commit('setDailyChipsNonDiscord', blockspinState.dailyChipsNonDiscord);
      commit('setBronzeRaffleGemPrice', blockspinState.bronzeRaffleGemPrice);
      commit('setSilverRaffleGemPrice', blockspinState.silverRaffleGemPrice);
      commit('setGoldRaffleGemPrice', blockspinState.goldRaffleGemPrice);
      commit('setWaxUsdPrice', blockspinState.waxUsdPrice);
      commit('setpolUsdPrice', blockspinState.polUsdPrice);
      commit('setBnbUsdPrice', blockspinState.bnbUsdPrice);
      commit('setSolUsdPrice', blockspinState.solUsdPrice);
      commit('setRoninUsdPrice', blockspinState.roninUsdPrice);
      commit('setJackpot', blockspinState.jackpot);
      commit('setJackbotCooldown', blockspinState.jackbotCooldown);
      commit('setJackbotPremiumServerCooldown', blockspinState.jackbotPremiumServerCooldown);
      commit('setJackbotPremiumServerDiscountPercentage', blockspinState.jackbotPremiumServerDiscountPercentage);
      commit('setJackbotPremiumServerMaxBet', blockspinState.jackbotPremiumServerMaxBet);
      commit('setJackbotPremiumServerPrice', blockspinState.jackbotPremiumServerPrice);
      commit('setJackbotPremiumUserCoold', blockspinState.jackbotPremiumUserCoold);
      commit('setJackbotPremiumUserDiscountPercentage', blockspinState.jackbotPremiumUserDiscountPercentage);
      commit('setJackbotPremiumUserMaxBet', blockspinState.jackbotPremiumUserMaxBet);
      commit('setJackbotPremiumUserPrice', blockspinState.jackbotPremiumUserPrice);
      commit('setXpPerLevelIncreasePercentage', blockspinState.xpPerLevelIncreasePercentage);
      commit('setDailyChipsPerLevelIncreasePercentage', blockspinState.dailyChipsPerLevelIncreasePercentage);
      commit('setBetsPossible', blockspinState.betsPossible);
      commit('setBigWinMultiplier', blockspinState.bigWinMultiplier);
      commit('setLeaderboardPrizeHourly', blockspinState.leaderboardPrizeHourly);
      commit('setLeaderboardPrizeDaily', blockspinState.leaderboardPrizeDaily);
      commit('setLeaderboardPrizeWeekly', blockspinState.leaderboardPrizeWeekly);
      commit('setLeaderboardPrizeSplit', blockspinState.leaderboardPrizeSplit);
      commit('setLeaderboardTokenPrizes', blockspinState.leaderboardTokenPrizes);
      commit('setGamesUnlockedForAll', blockspinState.gamesUnlockedForAll);
      commit('setGamesGemUnlockPrice', blockspinState.gamesGemUnlockPrice);
      commit('setGamesGemUnlockDiscount', blockspinState.gamesGemUnlockDiscount);
      commit('setLevelUpChipsRewardMultiplier', blockspinState.levelUpChipsRewardMultiplier);
      commit('setAchievementRewards', blockspinState.achievementRewards);
      commit('setUsdToGemsRate', blockspinState.usdToGemsRate);
      commit('setGuestMaxBets', blockspinState.guestMaxBets);
      commit('setLuckyWheelChipsWinAmounts', blockspinState.luckyWheelChipsWinAmounts);
      commit('setBoosts', blockspinState.boosts);
      commit('setMonthlyCards', blockspinState.monthlyCards);
      commit('setTotalNumEntries', blockspinState.totalNumEntries);
      commit('setPrizes', blockspinState.prizes);
      commit('setWinners', blockspinState.winners);
      commit('setTimestamp', blockspinState.timestamp);
      commit('setMillisecondsRemainingToday', blockspinState.millisecondsRemainingToday);
      commit('setMillisecondsRemainingThisWeek', blockspinState.millisecondsRemainingThisWeek);
      commit('setMillisecondsRemainingThisMonth', blockspinState.millisecondsRemainingThisMonth);
      commit('setGamesLeaderboardBetsPerGame', blockspinState.gamesLeaderboardBetsPerGame);
      commit('setGamesLeaderboardMinimunBet', blockspinState.gamesLeaderboardMinimunBet);
      commit('setGamesLeaderboardPrize', blockspinState.gamesLeaderboardPrize);
      commit('setGamesGemMiningRate', blockspinState.gamesGemMiningRate);


      initialFrontendTimestamp = Date.now();
      let initialTimestamp = blockspinState.timestamp;
      initialMillisecondsRemainingToday = blockspinState.millisecondsRemainingToday;
      initialMillisecondsRemainingThisWeek = blockspinState.millisecondsRemainingThisWeek;
      initialMillisecondsRemainingThisMonth = blockspinState.millisecondsRemainingThisMonth;

      if(updateMillisecondsRemainingInterval) {
        clearInterval(updateMillisecondsRemainingInterval);
      }
      updateMillisecondsRemainingInterval = setInterval(function() {
        const timeChange = Date.now() - initialFrontendTimestamp;
        commit('setTimestamp', initialTimestamp + timeChange);
        commit('setMillisecondsRemainingToday', initialMillisecondsRemainingToday - timeChange);
        commit('setMillisecondsRemainingThisWeek', initialMillisecondsRemainingThisWeek - timeChange);
        commit('setMillisecondsRemainingThisMonth', initialMillisecondsRemainingThisMonth - timeChange);

        const oneHour = 1000 * 60 * 60;
        const millisecondsRemainingUntilNextHour = getters.getMillisecondsRemainingToday % oneHour;
        if(getters.getMillisecondsRemainingToday < 0 ||
            (millisecondsRemainingUntilNextHour > (oneHour-7000) && millisecondsRemainingUntilNextHour < (oneHour-5000) )) {
          clearInterval(updateMillisecondsRemainingInterval);
          dispatch('getState');
          dispatch('getUser');
          dispatch('getLeaderboard');
        }
      }, 1000)

    },
    async setChain({ commit, dispatch, getters }, chain) {
      if(!chain && localStorage.getItem('chain')) {
        chain = localStorage.getItem('chain');
      } else if(!chain) {
        chain = defaultChain;
      }

      commit('setChain', chain);
      if (!getters.getWalletUsername && localStorage.getItem('wallet')) {
        //await dispatch('loginWallet', localStorage.getItem('wallet'));
      }
    },
    async payWithCreditCard({getters}, buyWithCreditCardData) {
      let isResolved = false;
      return new Promise((resolve, reject) => {
        blackSheepCreditCard(getters.getUserId, buyWithCreditCardData.productId, buyWithCreditCardData.quantity, (success, error, product) => {
          if(success) {
            isResolved = true;
            resolve({success, product, error});
          } else {
            console.log(error);
            if(error.message == "Server error, please try again") {
              isResolved = true;
              resolve({success, product, error: "Credit card payment only available for payments over $0.50"});
            } else if(error.message == "Error getting dynamic price") {
              isResolved = true;
              resolve({success, product, error: "Error getting price from server"});
            }
          }
        }, () => {
          if(!isResolved) {
            resolve({success: false});
          }
        });
      });
    },
    async buyChipsCredit({ dispatch }, buyChipsData) {
      let productId = 'chips' + buyChipsData.chipsPackage.toString();
      let buyWithCreditCardData = {productId, quantity: buyChipsData.quantity};
      const purchase = await dispatch('payWithCreditCard', buyWithCreditCardData);

      if(purchase.success) {
        getUserTries = 0;
        setTimeout(function() {
          dispatch("getUser", 'chips');
        }, getUserTimeoutTime);
      }
      return purchase;
    },
    async buyChips({ dispatch, getters}, buyChipsData) {
      const purchase = await Backend.buyChips(
          buyChipsData.chipsPackage,
          buyChipsData.quantity,
          buyChipsData.totalPrice,
          getters.getChain,
          getters.getWalletUsername,
          getters.getLoginData
      );
      if(purchase.purchaseId) {
        const tx = await wallet.value.makePurchase(purchase.purchaseId, buyChipsData.totalPrice);
        renderTrigger.value++;
        if(tx) {
          getUserTries = 0;
          setTimeout(function() {
            dispatch("getUser", 'chips');
          }, getUserTimeoutTime);
          return tx;
        }
      } else {
        if(purchase.error)
          window.alert(purchase.error);
        await dispatch("getState");
      }
      return null;
    },
    async buyMonthlyCardCredit({ getters, dispatch }, buyMonthlyCardData) {
      let purchase = await Backend.buyMonthlyCard(
          buyMonthlyCardData.monthlyCardType,
          0,
          "CREDIT",
          "",
          getters.getLoginData
      );
      if(purchase.success) {
        let productId = 'monthlyCard' + buyMonthlyCardData.monthlyCardType.charAt(0).toUpperCase() + buyMonthlyCardData.monthlyCardType.slice(1);
        let buyWithCreditCardData = {productId, quantity: 1};
        purchase = await dispatch('payWithCreditCard', buyWithCreditCardData);

        if(purchase.success) {
          getUserTries = 0;
          setTimeout(function() {
            dispatch("getUser", 'monthlyCard');
          }, getUserTimeoutTime);
        }
        return purchase;
      } else {
        await dispatch("getState");
        return purchase;
      }
    },
    async buyMonthlyCard({ dispatch, getters}, buyMonthlyCardData) {
      const purchase = await Backend.buyMonthlyCard(
          buyMonthlyCardData.monthlyCardType,
          buyMonthlyCardData.totalPrice,
          getters.getChain,
          getters.getWalletUsername,
          getters.getLoginData
      );
      if(purchase.purchaseId) {
        const tx = await wallet.value.makePurchase(purchase.purchaseId, buyMonthlyCardData.totalPrice);
        renderTrigger.value++;
        if(tx) {
          getUserTries = 0;
          setTimeout(function() {
            dispatch("getUser", 'monthlyCard');
          }, getUserTimeoutTime);
          return tx;
        }
      } else {
        await dispatch("getState");
        return purchase;
      }
      return null;
    },
    async buyBoostCredit({ getters, dispatch }, buyBoostData) {
      let purchase = await Backend.buyBoost(
          buyBoostData.boostType,
          buyBoostData.quantity,
          0,
          false,
          "CREDIT",
          "",
          getters.getLoginData
      );
      if(purchase.success) {
        let productId = 'boost' + buyBoostData.boostType.charAt(0).toUpperCase() + buyBoostData.boostType.slice(1);
        let buyWithCreditCardData = {productId, quantity: buyBoostData.quantity};
        purchase = await dispatch('payWithCreditCard', buyWithCreditCardData);

        if(purchase.success) {
          getUserTries = 0;
          setTimeout(function() {
            dispatch("getUser", 'boosts');
          }, getUserTimeoutTime);
        }
        return purchase;
      } else {
        await dispatch("getState");
        return purchase;
      }
    },
    async buyBoost({ dispatch, getters}, buyBoostData) {
      const purchase = await Backend.buyBoost(
          buyBoostData.boostType,
          buyBoostData.quantity,
          buyBoostData.totalPrice,
          buyBoostData.buyWithGems,
          getters.getChain,
          getters.getWalletUsername,
          getters.getLoginData
      );
      if(purchase.purchaseId) {
        const tx = await wallet.value.makePurchase(purchase.purchaseId, buyBoostData.totalPrice);
        renderTrigger.value++;
        if(tx) {
          getUserTries = 0;
          setTimeout(function() {
            dispatch("getUser", 'boosts');
          }, getUserTimeoutTime);
          return tx;
        }
        // gems purchase
      } else if(purchase.success) {
        await dispatch("getUser");
        return purchase;
      } else {
        await dispatch("getState");
        return purchase;
      }
      return null;
    },
    async buyJackbotPremiumUserCredit({ dispatch, getters }) {
      let purchase = await Backend.buyJackbotPremiumUser(
          0,
          "CREDIT",
          "",
          getters.getLoginData
      );
      if(purchase.success) {
        let productId = 'jackbotPremiumUser';
        let buyWithCreditCardData = {productId, quantity: 1};
        purchase = await dispatch('payWithCreditCard', buyWithCreditCardData);

        if(purchase.success) {
          getUserTries = 0;
          setTimeout(function() {
            dispatch("getUser", 'jackbotPremiumUser');
          }, getUserTimeoutTime);
        }
        return purchase;
      } else {
        await dispatch("getState");
        return purchase;
      }
    },
    //get price for current chain
    async getItemPrice({ state, getters }, priceUsd) {
      let price=priceUsd?priceUsd:0;
      try{
        /*
          waxUsdPrice: 0,
          polUsdPrice: 0,
          bnbUsdPrice: 0,
          solUsdPrice: 0,
        * */
        const chain=getters.getChain;
        if (chain.toLowerCase()==='wax'){
          price=price/state.waxUsdPrice;
        }
        if (chain.toLowerCase()==='bnb'){
          price=price/state.bnbUsdPrice;
        }
        if (chain.toLowerCase()==='sol'){
          price=price/state.solUsdPrice;
        }
        if (chain.toLowerCase()==='pol'){
          price=price/state.polUsdPrice;
        }
        price=price.toFixed(getters.getChainDecimals)
        price=Number(price);
      }catch (e) {
        console.log('getItemPrice catch: '+e.message+'\nstack: '+e.stack)
      }
      return price;
    },
    async buyJackbotPremiumUser({ dispatch, getters}, buyData) {
      try {
        const totalPrice = await dispatch("getItemPrice", buyData.totalPrice)
        const purchase = await Backend.buyJackbotPremiumUser(
            totalPrice,
            getters.getChain,
            getters.getWalletUsername,
            getters.getLoginData
        );
        MyLog('purchase: '+purchase);
        if (purchase && purchase.purchaseId) {
          const tx = await wallet.value.makePurchase(purchase.purchaseId, totalPrice);
          renderTrigger.value++;
          if (tx) {
            getUserTries = 0;
            setTimeout(function () {
              dispatch("getUser", 'jackbotPremiumUser');
            }, getUserTimeoutTime);
            return tx;
          }
        } else {
          if (purchase.error)
            window.alert(purchase.error);
          await dispatch("getState");
        }
        return null;
      }catch (e) {
        console.log('buyJackbotPremiumUser catch: '+e.message+'\nstack: '+e.stack)
      }
    },
    async buyJackbotPremiumServerCredit({ dispatch }, buyData = {productId:'jackbotPremiumServer', quantity: 1}) {
      let productId = 'jackbotPremiumServer';
      let buyWithCreditCardData = {productId, quantity: buyData.quantity? buyData.quantity : 1};
      const purchase = await dispatch('payWithCreditCard', buyWithCreditCardData);

      if(purchase.success) {
        getUserTries = 0;
        setTimeout(function() {
          dispatch("getUser", 'jackbotPremiumServerCredits');
        }, getUserTimeoutTime);
      }
      return purchase;
    },
    async buyJackbotPremiumServer({ dispatch, getters}, buyData) {
      const totalPrice=await dispatch("getItemPrice",buyData.totalPrice)
      const purchase = await Backend.buyJackbotPremiumServer(
          buyData.quantity? buyData.quantity : 1,
          totalPrice,
          getters.getChain,
          getters.getWalletUsername,
          getters.getLoginData
      );
      if(purchase.purchaseId) {
        const tx = await wallet.value.makePurchase(purchase.purchaseId, totalPrice);
        renderTrigger.value++;
        if(tx) {
          getUserTries = 0;
          setTimeout(function() {
            dispatch("getUser", 'jackbotPremiumServerCredits');
          }, getUserTimeoutTime);
          return tx;
        }
      } else {
        if(purchase.error)
          window.alert(purchase.error);
        await dispatch("getState");
      }
      return null;
    },
    async buyTickets({ commit, getters}, buyTicketsData) {
      const purchase = await Backend.buyTickets(
          buyTicketsData.ticketType,
          buyTicketsData.quantity,
          buyTicketsData.totalPrice,
          getters.getLoginData
      );
      if(purchase.success) {
        commit("setGems", getters.getGemsBalance - buyTicketsData.totalPrice);
        switch(buyTicketsData.ticketType) {
          case "bronze": {
            commit("setBronzeTickets", getters.getBronzeTicketsBalance + buyTicketsData.quantity);
            break;
          }
          case "silver": {
            commit("setSilverTickets", getters.getSilverTicketsBalance + buyTicketsData.quantity);
            break;
          }
          case "gold": {
            commit("setGoldTickets", getters.getGoldTicketsBalance + buyTicketsData.quantity);
            break;
          }
        }
      }
      return purchase;
    },
    async enterRaffle({ commit, getters}, enterRaffleData) {
      const entry = await Backend.enterRaffle(
          enterRaffleData.raffleType,
          enterRaffleData.quantity,
          getters.getLoginData
      );
      if(entry.success) {
        let numEntries = { ...getters.getNumEntries };
        numEntries[enterRaffleData.raffleType] += entry.numEntries;
        commit("setNumEntries", numEntries);

        let totalNumEntries = { ...getters.getTotalNumEntries };
        totalNumEntries[enterRaffleData.raffleType] += entry.numEntries;
        commit("setTotalNumEntries", totalNumEntries);

        switch(enterRaffleData.raffleType) {
          case "daily": {
            commit("setBronzeTickets", getters.getBronzeTicketsBalance - enterRaffleData.quantity);
            break;
          }
          case "weekly": {
            commit("setSilverTickets", getters.getSilverTicketsBalance - enterRaffleData.quantity);
            break;
          }
          case "monthly": {
            commit("setGoldTickets", getters.getGoldTicketsBalance - enterRaffleData.quantity);
            break;
          }
        }
      }
      return entry;
    },
    async claimNfts({ commit, getters, dispatch}, claimNftsData) {
      const claim = await Backend.claimNfts(
          claimNftsData.chain,
          claimNftsData.address,
          getters.getLoginData
      );
      if(!claim.error) {
        dispatch('getState');
        dispatch('getUser');
      }
      return claim;
    },
    async withdrawToken({ commit, getters, dispatch}, withdrawTokenData) {
      const withdraw = await Backend.withdrawToken(
          withdrawTokenData.chain,
          withdrawTokenData.nativeToken,
          withdrawTokenData.tokenAddress,
          withdrawTokenData.tokenSymbol,
          withdrawTokenData.address,
          getters.getLoginData
      );
      if(!withdraw.error) {
        dispatch('getUser');
      }
      return withdraw;
    },

    async getPrizeCode({ commit, getters, dispatch}, prizeId) {
      const getCode = await Backend.getPrizeCode(
          prizeId,
          getters.getLoginData
      );
      if(getCode.code) {
        dispatch('getState');
        dispatch('getUser');
      }
      return getCode;
    },
    async unlockGame({ commit, getters}, unlockGameData) {
      const unlock = await Backend.unlockGame(
          unlockGameData.game,
          unlockGameData.price,
          getters.getLoginData
      );
      if(unlock.success) {
        commit("setGems", getters.getGemsBalance - unlockGameData.price);
        commit("setUnlockedGame", unlockGameData.game);
      }
      return unlock;
    },
    async updateWalletBalance({ commit }) {
      let balance = 0;
      commit('setWalletBalance', balance);
      if (wallet.value.getUsername()) {
        balance = await wallet.value.getWalletBalance(wallet.value.getUsername());
        renderTrigger.value++;
      }
      commit('setWalletBalance', balance);
    },
    async loginWallet({ dispatch, commit, state }, walletName) {
      let wName=walletName;
      if(walletName === 'wax' || walletName === 'anchor') {
        commit('setChainHard', "WAX");
      }
      if(walletName === 'phantom' || walletName === 'solflare') {
        commit('setChainHard', "SOL");
      }
      if(walletName === 'ronin') {
        commit('setChainHard', "RON");
      }
      if(walletName === 'bnb') {
        commit('setChainHard', "BNB");
        wName='metamask';
      }
      if(walletName === 'pol') {
        commit('setChainHard', "POL");
        wName='metamask';
      }

      const objRet=await wallet.value.login(wName);
      renderTrigger.value++;

      if (objRet.success===true) {
        if (walletName === 'bnb') {
          state.metaMaskChain = "BNB";
        }
        if (walletName === 'pol') {
          state.metaMaskChain = "POL";
        }
      }
      else{
        state.errorPopupObj.titleErrorPopup='Connect wallet error';
        state.errorPopupObj.textErrorPopup=objRet.error;
        state.errorPopupObj.errorPopup=true;
      }

      await dispatch('updateWalletBalance');
    },
    async logoutWallet({ commit }) {
      await wallet.value.logout();
      renderTrigger.value++;
      commit('setWalletBalance', 0);
    },
    async getBets({ commit, dispatch }) {
      if (getBetsTimeout) {
        clearTimeout(getBetsTimeout);
      }

      // In case get user has not returned yet, getters.getLoginData would be null
      const loginData = JSON.parse(localStorage.getItem("loginData"));
      const bets = await Backend.getBets(loginData);
      if (bets) {
        commit('setBets', bets);
      }

      getBetsTimeout = setTimeout(function () {
        dispatch('getBets');
      }, recentBetsUpdateTime * 1000);
    },
    async getLeaderboard({ commit, dispatch }) {
      if (getLeaderboardTimeout) {
        clearTimeout(getLeaderboardTimeout);
      }

      // In case get user has not returned yet, getters.getLoginData would be null
      const loginData = JSON.parse(localStorage.getItem("loginData"));
      const leaderboard = await Backend.getLeaderboard(loginData);
      if (leaderboard) {
        commit('setLeaderboard', leaderboard);
      }

      getLeaderboardTimeout = setTimeout(function () {
        dispatch('getLeaderboard');
      }, leaderboardUpdateTime * 1000);
    },
    async claimChips({ commit, getters }) {
      const loginData = JSON.parse(localStorage.getItem("loginData"));
      const claim = await Backend.claimChips(loginData);
      if(claim.numChips) {
        commit("setChips", getters.getChipsBalance + claim.numChips);
        commit("setClaimedFreeChipsToday", true);
      }
      return claim;
    },
    async spinWheel({ commit, getters, dispatch }, spinScratch) {
      const loginData = JSON.parse(localStorage.getItem("loginData"));

      let skipRequirements=false;
      //skipRequirements=getters.getEnv === 'test';

      await dispatch("getUser");
      const spin = await Backend.spinWheel(loginData, spinScratch, skipRequirements);
      dispatch("getUser");
      return spin;
    },
    async clickShopCurrency({ state, getters, commit, dispatch }, currency) {
      if (currency==='wax'){
        state.shopCurrency='wax';
        state.isShopWalletSelected=false;
        commit('setChain', 'WAX');
      }
      if (currency==='bnb'){
        state.shopCurrency='bnb';
        state.isShopWalletSelected=false;
        commit('setChain', 'BNB');
      }
      if (currency==='pol'){
        state.shopCurrency='pol';
        state.isShopWalletSelected=false;
        commit('setChain', 'POL');
      }
      if (currency==='sol'){
        state.shopCurrency='sol';
        state.isShopWalletSelected=false;
        commit('setChain', 'SOL');
      }
      if (currency==='usd'){
        state.shopCurrency='usd';
        state.isShopWalletSelected=false;
        commit('setChain', 'USD');
      }
      if (getters.walletConnected){
        await dispatch('updateWalletBalance');
      }
    },
    async openNotification({ commit, getters }, index){
      commit("setNotificationOpened", index);

      const loginData = JSON.parse(localStorage.getItem("loginData"));
      await Backend.readNotification(loginData, getters.getNotifications[index]._id);
    },
    async claimNotification({ commit, getters, dispatch }, index){

      const loginData = JSON.parse(localStorage.getItem("loginData"));
      const notification = getters.getNotifications[index];
      const claim = await Backend.claimNotification(loginData, notification._id);
      if(claim.success) {
        switch(notification.prizeType) {
          case "chips": {
            commit("setChips", getters.getChipsBalance + notification.prizeAmount);
            break;
          }
          case "gems": {
            commit("setGems", getters.getGemsBalance + notification.prizeAmount);
            break;
          }
          case "bronzeTickets": {
            commit("setBronzeTickets", getters.getBronzeTicketsBalance + notification.prizeAmount);
            break;
          }
          case "silverTickets": {
            commit("setSilverTickets", getters.getSilverTicketsBalance + notification.prizeAmount);
            break;
          }
          case "goldTickets": {
            commit("setGoldTickets", getters.getGoldTicketsBalance + notification.prizeAmount);
            break;
          }
          case "monthlyCard": {
            await dispatch("getUser");
            break;
          }
        }
        commit("setNotificationClaimed", index);
      }
      return claim;
    },
    async redeemCode({ commit, getters, dispatch }, code){
      const loginData = JSON.parse(localStorage.getItem("loginData"));
      const redeem = await Backend.redeemCode(code, loginData);
      await dispatch("getUser");
      return redeem;
    },
  }
})
