
import config from '../config.js';
import Eos from './eos.js';
import Sol from './sol.js';
import Ronin from './ronin.js';
import Eth from './eth.js';

export default class Wallet {

    constructor(chain) {
        this.chain = null
        this.setChain(chain);

        this.waxWallet = new Eos("WAX");
        this.solWallet = new Sol("SOL");
        this.roninWallet = new Ronin("RON");
        this.polWallet = new Eth("POL");
        this.bnbWallet = new Eth("BNB");
    }

    setChain(chain) {
        if (chain === this.chain) {
            return;
        }
        this.chain = chain;
        if(config[chain]) {
            switch(chain) {
                case "WAX": {
                    this.chainWallet = this.waxWallet;
                    break;
                }
                case "SOL": {
                    this.chainWallet = this.solWallet;
                    break;
                }
                case "RON": {
                    this.chainWallet = this.roninWallet;
                    break;
                }
                case "POL": {
                    this.chainWallet = this.polWallet;
                    break;
                }
                case "BNB": {
                    this.chainWallet = this.bnbWallet;
                    break;
                }
            }
        }
    }

    getUsername() {
        if(this.chainWallet) {
            return this.chainWallet.getUsername();
        }
        return null;
    }

    async login(wallet) {
        return this.chainWallet.login(wallet);
    }

    async logout() {
        return this.chainWallet.logout();
    }

    async makePurchase(purchaseId, totalPrice) {
        return this.chainWallet.makePurchase(purchaseId, totalPrice);
    };

    async getWalletBalance(accountName) {
        return this.chainWallet.getWalletBalance(accountName);
    }

}
